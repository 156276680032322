<template>
    <div id="nav-profile">
      <SideBar @Show="toggleSidebar" :class="{open:this.$store.state.profileSideBar }"  />
    <div @click="toggleOverlay" class="overlay d-block d-md-none" :class="{ open: this.$store.state.profileSideBar }"></div>
    <TopBar @ShowSide="toggleSidebar" />

    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
    data() {
      return {
        show: false,
      };
   },
    components: {
        SideBar: defineAsyncComponent( () => import('@/components/Common/Profile/SideBar.vue') ),
        TopBar: defineAsyncComponent( () => import('@/components/Common/Profile/TopBar.vue') ),
    },
    computed:{

    },
    mounted() {

        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll > 0) {
                $('#nav-profile').addClass("stiky");
            } else {
                $('#nav-profile').removeClass("stiky");
            }
        });

    },
    methods:{
      toggleSidebar() {
        this.$store.commit("SET_profileSideBar", !this.$store.state.profileSideBar);
      },
      toggleOverlay() {
        this.$store.commit("SET_profileSideBar", !this.$store.state.profileSideBar);
      },
    }

}
</script>
<style lang="scss"></style>
